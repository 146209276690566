<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <div class="flex items-center">
        <img src="@/assets/images/icons8-money-transfer-96.png" class="h-20 ml-2" />
        <div class="text-xl font-bold">لیست خرید اشتراک</div>
      </div>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Group"></Icon>
        {{ group.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ group.gid }}</div>
    </template>
    <template #default>
      <div class="flex flex-wrap p-3 bg-teal-600 text-center text-teal-50">
        <div class="w-6/12 md:w-3/12">سفارش دهنده</div>
        <div class="w-6/12 md:w-3/12">اشتراک</div>
        <div class="w-6/12 md:w-2/12">قیمت (تومان)</div>
        <div class="w-6/12 md:w-4/12">تاریخ</div>
      </div>
      <div
        v-for="pay in pays"
        :key="pay.id"
        class="flex flex-wrap p-3 odd:bg-blueGray-100 text-center"
      >
        <div class="w-6/12 md:w-3/12">{{ pay.user?.first_name ?? "نامشخص" }}</div>
        <div class="w-6/12 md:w-3/12">{{ pay.account?.name }}</div>
        <div class="w-6/12 md:w-2/12">{{ $number.format(pay.amount) }}</div>
        <div class="w-6/12 md:w-4/12">{{ pay.date }}</div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";

export default {
  components: {
    BodySimple,
    Icon,
  },
  data() {
    return {
      firstLoading: true,
      group: {},
      pays: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      // $this.firstLoading = true;
      $this.$axios
        .get("/api/group-income/account/" + $this.$route.params.groupId, {
          params: {
            id: $this.$store.state.user.id,
            // date: $this.date,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.group = response.data.group;
          $this.pays = response.data.pays;
          // $this.users = response.data.users;
          // $this.date = response.data.date;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
          $this.sending = false;
        });
    },
  },
};
</script>

<style></style>
